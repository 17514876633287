
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header"
      
        style={{zIndex:"3"}}
      >
        <img src="/title.png"/>

    
      </header>
      <body className='App-body'>
        
        <video
          autoPlay 
          loop
          muted
  
          style={{
            position: "absolute",
            width: "100%",
            left: "0%",
            top:"15%",
            height: "90%",
            objectFit: "cover",
            transform: "translate(0%, 0%)",
            zIndex: "1"
          }}


        >
          <source src="/video/background.mov" type="video/mp4"/>
        </video>

        <div
          style={{zIndex:"2"}}     
        >
          <p><b>
          Asteroidi provides strategy consulting and 
            </b>
          </p>
          <p><b>
          on-demand advanced analytics and AI prototyping services
            </b>
          </p>
          <p><b>
          to smart decision makers and investors who need
            </b>
          </p>
          <p><b>
          differentiated and tangible insights
            </b>
          </p>
          <p><b>
          in a short timeframe with high ROI
            </b>
          </p>
        </div>
        

        <div

          style={{zIndex:"2", position: "absolute", bottom:"5vh", fontSize:"14px"}}   
        >
           

        </div>
       
        
        
        
      </body>

      
      
    </div>
  );
}

export default App;
